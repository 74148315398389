export default {
  modules: {
    generatedReport: {
      namespaced: true,
      state: {},
      mutations: {},
      actions: {},
      getters: {}
    }
  }
}
