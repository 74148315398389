import "./registerServiceWorker";

import { sync } from "vuex-router-sync";
import Vue from "vue";
import App from "./App";
import EvaIcons from 'vue-eva-icons';
import HighchartsVue from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import Multiselect from 'vue-multiselect';
import VueSwal from 'vue-swal'
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import Pattern from 'highcharts/modules/pattern-fill'
import { abilitiesPlugin } from '@casl/vue'
import { Ability } from '@casl/ability'
import hcMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
var Highcharts = require('highcharts'),
HighchartsGroupedCategories = require('highcharts-grouped-categories')(Highcharts);
import exportingInit from 'highcharts/modules/exporting';
import { VueSpinners } from '@saeris/vue-spinners';
import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });


if (typeof Highcharts === 'object' && typeof window !== 'undefined') {
  hcMore(Highcharts);
  stockInit(Highcharts);
  solidGauge(Highcharts);
}
Vue.use(HighchartsVue);
Vue.use(Multiselect);
Vue.use(EvaIcons);
Vue.use(VueSpinners);
Vue.use(VueSwal);
Vue.use(abilitiesPlugin, new Ability([]))

HCSoldGauge(Highcharts);
Pattern(Highcharts);
exportingInit(Highcharts);

/**
 * This is the Vuex store and it is
 * avaible to all your components
 */
import store from "./store";

/**
 * This is the Router
 */
import router from "./router";

/**
 * $http plugin based on axios
 */
import httpPlugin from "./plugins/http";

/**
 * eventbus plugin
 */
import eventbus from "./plugins/eventbus";

/**
 * Vue Bootstrap
 */
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
/**
 * jQuery and Bootstrap includes
 */
require("./includes");
/**
 * Element UI
 */
/**
 * Make $http avaible to all components
 * Send store and router to httpPlugin (injection)
 */
Vue.use(httpPlugin, { store, router });

/**
 * Make $bus avaible to all components
 */
Vue.use(eventbus);

// Effortlessly keep vue-router and vuex store in sync.
sync(store, router); // https://github.com/vuejs/vuex-router-sync/tree/next

export const eventBus = new Vue();
/* eslint-disable no-new */
new Vue({
  store, // injects the Store into all components
  router, // make Router work with the application
  el: "#app",
  render: h => h(App)
});
