const vuexFilters = {
  state: {
    school: [],
    teacher: [],
    courseSections: [],
    courseType: [],
    typeFirst: {},
    typeSecond: {},

    scoreFirst: false,
    scoreSecond: false,

    yearFirst: false,
    yearSecond: false,

    academicYear: false,

    gradeType: [],
    grades: false,
    benchmark: false,
    chooseStandardBased: false,

    firstAssessment: null,
    secondAssessment: null,
    firstAssessmentType: null,
    secondAssessmentType: null,

    chosenDistrictTerm: [],
    chosenSubGroups: {
      ethnicity: [],
      ell: [],
      gender: [],
      lunchStatus: [],
      specialEd: [],
      custom: []
    }
  },
  mutations: {
    SET_CHOSEN_SUBGROUPS (state, obj) {
      state.chosenSubGroups[obj.key] = obj.value
    },
    SET_SCHOOL(state, obj) {
      state.school = obj;
    },
    SET_TEACHER(state, obj) {
      state.teacher = obj;
    },
    SET_COURSE_SECTIONS(state, obj) {
      state.courseSections = obj;
    },
    SET_COURSE_TYPE(state, obj) {
      state.courseType = obj;
    },
    SET_FIRST_TYPE(state, obj) {
      state.typeFirst = obj;
    },
    SET_SECOND_TYPE(state, obj) {
      state.typeSecond = obj;
    },
    SET_FIRST_SCORE(state, obj) {
      state.scoreFirst = obj;
    },
    SET_SECOND_SCORE(state, obj) {
      state.scoreSecond = obj;
    },
    SET_FIRST_YEAR(state, obj) {
      state.yearFirst = obj;
    },
    SET_SECOND_YEAR(state, obj) {
      state.yearSecond = obj;
    },
    SET_GRADE_TYPE(state, obj) {
      state.gradeType = obj;
    },
    SET_GRADES(state, obj) {
      state.grades = obj;
    },
    SET_BENCHMARK(state, obj) {
      state.benchmark = obj;
    },
    SET_STANDARD_BASED(state, value) {
      state.chooseStandardBased = value
    },
    setChosenDistrictTerm (state, data) {
      state.chosenDistrictTerm = data
    },
    selectAcademicYear (state, year) {
      state.academicYear = year
    },
    selectFirstAssessment(state, data) {
      state.firstAssessment = data
    },
    selectFirstAssessmentType(state, data) {
      state.firstAssessmentType = data
    },
    selectSecondAssessment(state, data) {
      state.secondAssessment = data
    },
    selectSecondAssessmentType(state, data) {
      state.secondAssessmentType = data
    }
  },
  getters: {
    getChosenDistrictTerm (state) {
      return state.chosenDistrictTerm
    },
    GET_CHOSEN_SCHOOL(state) {
      return state.school;
    },
    GET_CHOSEN_TEACHER(state) {
      return state.teacher;
    },
    GET_CHOSEN_COURSE_SECTIONS(state) {
      return state.courseSections;
    },
    GET_CHOSEN_COURSE_TYPE(state) {
      return state.courseType;
    },
    GET_CHOSEN_FIRST_TYPE(state) {
      return state.typeFirst;
    },
    GET_CHOSEN_SECOND_TYPE(state) {
      return state.typeSecond;
    },
    GET_CHOSEN_FIRST_SCORE(state) {
      return state.scoreFirst;
    },
    GET_CHOSEN_SECOND_SCORE(state) {
      return state.scoreSecond;
    },
    GET_CHOSEN_FIRST_YEAR(state) {
      return state.yearFirst;
    },
    GET_CHOSEN_SECOND_YEAR(state) {
      return state.yearSecond;
    },
    GET_CHOSEN_GRADE_TYPE(state) {
      return state.gradeType;
    },
    GET_CHOSEN_GRADES(state) {
      return state.grades;
    },
    GET_CHOSEN_BENCHMARK(state) {
      return state.benchmark;
    },
    GET_STANDARD_BASED(state) {
      return state.chooseStandardBased
    },
    GET_CHOSEN_SUBGROUPS (state) {
      return state.chosenSubGroups
    },
    getAcademicYear (state) {
      return state.academicYear
    },
    getSelectedFirstAssessment(state) {
      return state.firstAssessment
    },
    getSelectedFirstAssessmentType(state) {
      return state.firstAssessmentType
    },
    getSelectedSecondAssessment(state) {
      return state.secondAssessment
    },
    getSelectedSecondAssessmentType(state) {
      return state.secondAssessmentType
    }
  }
};

export default vuexFilters;
