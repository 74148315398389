import store from "../store";
import router from './index'
import checkUserToken from "../middleware/auth";

const needAuth = auth => auth === true;
const beforeEach = (to, from, next) => {
  const auth = to.meta.requiredAuth;
  /**
   * Clears all global feedback message
   * that might be visible
   */
  store.dispatch("resetMessages");

  /**
   * If route doesn't require authentication be normally accessed.
   */
  if (!needAuth(auth)) {
    next();
    return; // return to prevent the code from continuing in its flow
    // With this flow `else` or `else if` is not necessary
  }

  checkUserToken(next, router);

  /**
   * Otherwise  if authentication is required login.
   */
  // store
  //   .dispatch("getUserByToken", {token: store.state.userToken})
  //   .then((response) => {
  //     localStorage.setItem('userToken', response.data.user);
  //     next(); // can access the route
  //   })
};

export default beforeEach;
