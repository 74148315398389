// https://vuex.vuejs.org/en/state.html

export default {
  messages: {
    success: "",
    error: [],
    warning: "",
    validation: [],
  },
  districtUsers: [],
  drawToggle: false,
  fetching: false,
  user: null !== localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  userToken: null !== localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null,
  selectedFilters: {
    typeReport: '',
    school: [],
    gradeType: null,
    grades: [],
    teacher: null,
    courseType: null,
    yearFirst: false,
    yearSecond: false,
    scoreFirst: null,
    scoreSecond: null,
    typeFirst: null,
    typeSecond: null,
    benchmark: false,
    terms: [],
    courseSections: []
  }
};
