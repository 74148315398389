// https://vuex.vuejs.org/en/mutations.html

import * as TYPES from "./types";

/* eslint-disable no-param-reassign */
export default {
  DRAWER_TOGGLER(state, val) {
    state.drawToggle = val;
  },
  [TYPES.MAIN_SET_FETCHING](state, obj) {
    state.fetching = obj.fetching;
  },
  [TYPES.MAIN_SET_MESSAGE](state, obj) {
    state.messages[obj.type] = obj.message;
  },
  [TYPES.SET_TOKEN](state, obj) {
    state.userToken = obj.token
  },
  [TYPES.SET_USER](state, obj) {
    state.user = obj.user
  },
  setDistrictUsers (state, users) {
    state.districtUsers = users
  },
  createOrUpdateSelectedFilters (state, obj) {
    const filters = Object.assign({}, state.selectedFilters)
    filters[obj.key] = obj.value
    state.selectedFilters = filters
  },
  clearSelectedFilters (state) {
    state.selectedFilters = {}
  },
  setFilters (state, payload) {
    state.selectedFilters = payload
  }
  // [TYPES.LOGOUT](state) {
  //   state.userToken = null
  //   state.user      = null
  //   localStorage.removeItem('userToken')
  //   localStorage.removeItem('user')
  // }
};
