<template>
    <div></div>
</template>

<script>
import { applicationUrl } from "../config";
export default {
  created() {
    this.authUser()
  },
  methods: {
    authUser() {
      this.$store.dispatch('authUser', { redirectUrl: applicationUrl + '/get-token' })
        .then(response => {
          window.location.replace(response);
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
