// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import

import {
  routes as FullDashboard
} from "./FullDashboard";
import {
  routes as GenerateReport
} from "./GenerateReport";
import {
  routes as ReportDetails
} from "./ReportDetails";
import {
  routes as ActiveDistricts
} from "./ActiveDistricts";

// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Spread_operator
// Thus a new array is created, containing all objects that match the routes.
// ...dashboard must be the last one because of the catchall instruction
export default [...GenerateReport, ...ReportDetails, ...FullDashboard, ...ActiveDistricts];
