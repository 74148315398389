const ReportDetails = () => import("./main");

export default [
  {
    name: "report-details",
    path: "/report-details/:id",
    component: ReportDetails
  },
  {
    name: "report-details",
    path: "/report-details/:id/:userToken",
    component: ReportDetails
  }];
