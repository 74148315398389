<template>
  <div id="app">
    <div class="main-wrapper">
<!--      <root-header v-if="$route.name === 'full-dashboard'"></root-header>-->
<!--      <root-header v-if="$route.name === 'report-details'"></root-header>-->
      <!-- <root-left-layout v-if="false"></root-left-layout> -->
      <router-view></router-view>
    </div>
  </div>
</template>



<script>
import { mapGetters } from "vuex";

export default {
  components: {
    // RootHeader: () => import("./components/Root/Header.vue")
    // RootLeftLayout: () => import("./components/Root/LeftLayout.vue")
  },
  created() {},
  computed: {
    ...mapGetters(["shouldShowNavigation"])
  },
  methods: {}
};
</script>

<style src="../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" src="./assets/sass/app.scss">
</style>

