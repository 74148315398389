import store from "../store";

export default function checkUserToken(next, router) {
  if(undefined === store.state.userToken || null === store.state.userToken || '' === store.state.userToken){
      router.push({ name: 'auth-user' })
  } else {
      store.dispatch('getUserByToken', {token: store.state.userToken})
          .then(response => {
              if('User token authentication failure.' !== response.data.user) {
                  store.commit('SET_USER', { user: response.data.user })
                  localStorage.setItem('user', JSON.stringify(response.data.user))
                  next();
              } else {
                  router.push({name: 'auth-user'})
              }
          });
  }

}
