import Vue from "vue";
import Router from "vue-router";
import beforeEach from "./beforeEach";
import { routes as views } from "../views";
import GetToken from '../components/GetToken'
import AuthUser from '../components/AuthUser'

Vue.use(Router);

// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Spread_operator
const routes = [
  {
    name: "auth-user",
    path: "/auth-user",
    component: AuthUser
  },
  {
    name: "get-token",
    path: "/get-token",
    component: GetToken
  },
  ...views
];

const router = new Router({
  routes,
  linkActiveClass: "active",
  mode: "history" // do not use /#/.
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
      context.next(...parameters);
    // Then run the subsequent Middleware with a new
      // `nextMiddleware()` callback.
      const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

/**
 * Before a route is resolved we check for
 * the token if the route is marked as
 * requireAuth.
 */
router.beforeEach(beforeEach);

export default router;
