import { isArray } from "lodash";
import mainStore from '../../store'

export default (http, store, router) => {
  // https://github.com/mzabriskie/axios#interceptors
  http.interceptors.response.use(
    response => {
      if ('User token authentication failure.' === response.data.message) {
        router.push({ name: 'auth-user' })
      }
      return response
    },
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    error => {
      const { response } = error;

      if ('User token authentication failure.' === response.data.message) {
        router.push({ name: 'auth-user', query: { redirectUrl: window.location.href } })
      }

      /**
       * If token is either expired, not provided or invalid
       * then redirect to login. On server side the error
       * messages can be changed on app/Providers/EventServiceProvider.php
       */
      // if ([401, 400].indexOf(response.status) > -1) {
      //   router.push({ name: "auth.signin" });
      // }
      /**
       * Error messages are sent in arrays
       */
      // if (isArray(response.data)) {
      //   store.dispatch("setMessage", {
      //     type: "error",
      //     message: response.data.messages
      //   });
        /**
         * Laravel generated validation errors are
         * sent in an object
         */
      // } else {
      //   store.dispatch("setMessage", {
      //     type: "validation",
      //     message: response.data
      //   });
      // }

      // store.dispatch("setFetching", { fetching: false });

      return Promise.reject(error);
    }
  );
};
