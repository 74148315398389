import { http } from "../../plugins/http";

export default {
  modules: {
    dashboard: {
      namespaced: true,
      state: {
        searchByName: '',
        isOpenNavMenuDrawer: true,
        availableToInviteUsers: [],
        participants: [],
        selectedYear: null,
        selectedReport: null
      },
      mutations: {
        setSearch (state, payload) {
          state.searchByName = payload
        },
        selectYear (state, payload) {
          state.selectedYear = payload.year;
        },
        setNavMenuDrawer (state, val) {
          state.isOpenNavMenuDrawer = val
        },
        selectReport (state, payload) {
          state.selectedReport = payload.report
        },
        setParticipants (state, payload) {
          if (undefined === payload.users && undefined === payload.participants) {
            return (state.participants = [])
          }
          state.participants = payload.users.filter(user =>
            undefined !== payload.participants.find(participant => Number(participant.user_id) === Number(user.id))
          )
            // Set to these User Objects properties which we needs
            .map(user => {
              let UserObject = {}
              const Participant = payload.participants.find(participant => Number(participant.user_id) === Number(user.id))
              if (undefined !== Participant) {
                UserObject.id = Participant.id
                UserObject.user_id = Participant.user_id
                UserObject.email = user.email
                UserObject.title = user.title
                UserObject.role = Participant.role
                UserObject.firstName = user.firstName
                UserObject.lastName = user.lastName
              }
              return UserObject
            })
        },
        setAvailableToInviteUsers (state, payload) {
          if (undefined === payload.users && undefined === payload.participants) {
            return (state.availableToInviteUsers = [])
          }
          state.availableToInviteUsers = payload.users.filter(
            user =>
              undefined ===
              payload.participants.find(
                participant => Number(participant.user_id) === Number(user.id)
              )
          )
        }
      },
      actions: {
        notifyParticipant (store, data) {
          return new Promise((resolve, reject) => {
            http({
              url: `requests/notify-participants`,
              method: 'post',
              data
            })
              .then(response => resolve(response))
              .catch(error => reject(error))
          })
        },
        inviteUsersToReport (store, data) {
          return new Promise((resolve, reject) => {
            http({
              url: `requests/invite-participants`,
              method: 'post',
              data
            })
              .then(response => resolve(response.data.data))
              .catch(error => reject(error))
          })
        },
        removeParticipant (store, data) {
          return new Promise((resolve, reject) => {
            http({
              url: `requests/remove-participant`,
              method: 'post',
              data
            })
              .then(response => resolve(response.data.data))
              .catch(error => reject(error))
          })
        }
      },
      getters: {
        getSearch (state) {
          return state.searchByName
        },
        getSelectedReport (state) {
          return state.selectedReport
        },
        getNavMenuDrawerState (state) {
          return state.isOpenNavMenuDrawer
        },
        getAvailableToInviteUsers (state) {
          return state.availableToInviteUsers
        },
        getParticipants (state) {
          return state.participants
        },
        getSelectedYear (state) {
          return state.selectedYear
        }
      }
    }
  }
}
