<template>
    <div></div>
</template>

<script>
import { setToken } from "../plugins/http";
import {AbilityBuilder} from "@casl/ability";

export default {
  mounted() {
    this.getUserToken()
  },
  methods: {
    getUserToken() {
      var url_string = window.location.href; // window.location.href
      var url = new URL(url_string);
      var userToken = url.searchParams.get("ut");

      this.$store.commit('SET_TOKEN', {token: userToken})
      localStorage.setItem('userToken', userToken)
      setToken(userToken)
      this.getUserByToken()
    },
    getUserByToken() {
      const ReportTypeRedirect = localStorage.getItem('reportTypeRedirect')
      this.$store.dispatch('getUserByToken', { token: this.$store.state.userToken })
        .then(response => {
          this.$store.commit('SET_USER', { user: response.data.user })
          localStorage.setItem('user', JSON.stringify(response.data.user))
          const abilities = []
          if (0 < response.data.aimPermissions.length) {
            response.data.aimPermissions.forEach(ability =>
              abilities.push({ action: ability.slug })
            )
          }
          this.$ability.update(abilities)
          this.setUserAppPermission(response.data.userAppPermissions)
          const redirectURL = localStorage.getItem('redirectUri')
          if (undefined !== redirectURL && 'report-details' === redirectURL) {
            const reportNumber = localStorage.getItem('reportNumber')
              localStorage.removeItem('redirectUri')
              localStorage.removeItem('reportNumber')
            return this.$router.push({ name: "report-details", params: { id: reportNumber } })
          }

          if (undefined !== ReportTypeRedirect) {
            this.$router.push({ name: 'home', query: { report: ReportTypeRedirect } })
          } else {
            this.$router.push({ name: 'home' })
          }
        })
    },
    /**
     * This method set user permissions to get access for some part of this application.
     * @param permissions
     */
    setUserAppPermission (permissions) {
      const { can, rules } = new AbilityBuilder();
      if (0 < permissions.length) {
        permissions.forEach((permission) => {
          if (1 === Number(permission.enabled)) {
            can(permission.appId, 'app')
          }
        })
      }
      this.$ability.update(rules);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
