import { http } from "../../plugins/http";
import vuexFilters from "./components/Filters/vuexFilters";
import _ from 'lodash';

const vuex = {
    modules: {
        report: {
            namespaced: true,
            state: {
                selectedRequest: null,
                userRequests: null,
                textError: '',
                ...vuexFilters.state,
                chosenCorrelationType: '',
                schools: [],
                gradeOptions: [],
                gradeTypes: [],
                reports: null,
                sortResult: [], // For sorting a result from server
                /**
                 * All course sections by school which receive from API. For example: Algebra, geometry, etc...
                 */
                allCourses: [],
                allCourseTypes: [], // Course types. For example: Mathematics, Reading, etc...
                measurementTypes: [],
                districtTerms: [],

                subGroup: {
                    ethnicity: [],
                    ell: [],
                    gender: [],
                    lunchStatus: [],
                    specialEd: []
                },

                /**
                 * If a user selects student graphs by a teacher.
                 */
                studentDetails: {
                    reports: [],
                    benchmark: null,
                    filters: {
                        teacher: '',
                        courseSectionName: ''
                    },
                    mainSummary: {}
                },

                dataScores: [],
                year: '',
                loader: false,

                selectedSummaryData: null,
                // All Teacher by Schools
                teacherList: [],
                // Result data with chart info
                resultData: null
            },
            mutations: {
                setDistrictTerms (state, data) {
                    state.districtTerms = data
                },
                setSelectedRequest (state, data) {
                    state.selectedRequest = data
                },
                updateUserRequestName (state, updatedRequest) {
                    let UpdateRequestIndex = state.userRequests.data.findIndex(request => Number(request.id) === Number(updatedRequest.id))
                    if (-1 !== UpdateRequestIndex) {
                        state.userRequests.data[UpdateRequestIndex].name = updatedRequest.name
                    }
                },
                setUserRequests (state, data) {
                    state.userRequests = data
                },
                updateReportInUserRequest (state, data) {
                    const editingRequestId = state.userRequests.findIndex(request => request.id === data.id)
                    if (-1 !== editingRequestId) {
                        state.userRequests[editingRequestId].report = data.report
                    }
                },
                setSummaryHighlight (state, data) {
                    if (!_.isEqual(data, state.selectedSummaryData)) {
                        state.selectedSummaryData = data
                    } else {
                        state.selectedSummaryData = null
                    }
                },
                ...vuexFilters.mutations,
                SET_TEXT_ERROR(state, obj) {
                    state.textError = obj.textError
                },
                SET_STUDENT_DETAILS(state, obj) {
                    state.studentDetails = obj;
                },
                SET_RESULT_DATA(state, obj) {
                    state.resultData = null !== obj && undefined !== obj.resultData ? obj.resultData : null
                },
                SET_LOADER(state, obj) {
                    state.loader = obj.loader
                },
                SET_YEAR(state, obj) {
                    state.year = obj.year
                },
                CHOSE_CORRELATION_TYPE(state, obj) {
                    state.chosenCorrelationType = obj.type
                },
                SET_SCHOOL_BY_DISTRICT(state, obj) {
                    state.schools = obj.schools
                },
                SET_COURSE_TYPES(state, obj) {
                    state.allCourseTypes = obj.courseTypes
                },
                SET_COURSES(state, obj) {
                    state.allCourses = obj.courses
                },
                SET_MEASUREMENT_TYPES(state, obj) {
                    state.measurementTypes = obj.measurementTypes
                },
                SET_DATASCORES(state, obj) {
                    state.dataScores = obj.dataScores
                },
                SET_TEACHER_LIST(state, obj) {
                    state.teacherList = obj.teacherList
                },
                SET_REPORTS(state, obj) {
                    state.reports = obj.reports
                    localStorage.setItem('reports', JSON.stringify(obj.reports))
                },
                SET_SORT_RESULT(state, obj) {
                    state.sortResult = obj.sortResult
                },
                SET_SUBGROUP(state, obj) {
                    state.subGroup[obj.key] = obj.value
                },
                SET_GRADE_OPTIONS(state, obj) {
                    state.gradeOptions = obj
                },
                SET_GRADE_TYPES(state, obj) {
                    state.gradeTypes = obj
                }
            },
            actions: {
                createDistrict: function(state, obj) {
                    return new Promise(function(resolve, reject) {
                        http({
                            method: 'POST',
                            url: 'wide-reports/create-district-wide-reports',
                            data: obj.data
                        })
                        .then(function(response) {
                            return resolve(response);
                        })
                        .catch(function(err) {
                            return reject(err)
                        })
                    })
                },
                downloadTable(store, obj) {
                    return new Promise((resolve, reject) => {
                        http({
                            url: 'print',
                            method: 'POST',
                            data: obj
                        })
                          .then((response) => resolve(response.data))
                          .catch((error) => reject(error))
                    })
                },
                saveReport(store, obj) {
                    return new Promise((resolve) => {
                        http({
                            url: 'report',
                            method: 'POST',
                            data: obj.reportData
                        })
                            .then((response) => resolve(response))
                    })
                },
                getSchoolByDistrict(store, obj) {
                    return new Promise((resolve, reject) => {
                        http({
                            url: 'get_school_by_district',
                            method: 'POST',
                            data: JSON.parse(obj.user)
                        })
                            .then(response => {
                                store.commit('SET_SCHOOL_BY_DISTRICT', { schools: response.data })
                                resolve(response)
                            })
                            .catch((error) => reject(error))
                    })
                },
                getInitCourses(store, obj) {
                    return new Promise((resolve, reject) => {
                        http({
                            url: 'get_init_courses',
                            method: 'POST',
                            data: obj.settings
                        })
                            .then(response => resolve(response.data))
                            .catch((error) => reject(error.response))
                    })
                },
                getScoresByStudentId(store, obj) {
                    return new Promise((resolve, reject) => {
                        http({
                            url: 'get_scores_by_student_id',
                            method: 'POST',
                            data: obj.settings
                        })
                            .then(response => resolve(response))
                            .catch((error) => reject(error.response))
                    })
                },
                generateReport(store, obj) {
                    return new Promise((resolve, reject) => {
                        http({
                            url: 'generate_report',
                            method: 'POST',
                            data: obj.dataForFilters
                        })
                            .then(response => resolve(response))
                            .catch(error => reject(error))
                    })
                },

                getTeachers(store, obj) {
                    return new Promise((resolve, reject) => {
                        http({
                            url: 'filters/get_teachers',
                            method: 'POST',
                            data: obj
                        })
                          .then(response => resolve(response))
                          .catch(error => reject(error.response))
                    })
                },

                getCourseTypes(store, obj) {
                    return new Promise((resolve, reject) => {
                        http({
                            url: 'filters/get_course_types',
                            method: 'POST',
                            data: obj
                        })
                          .then(response => resolve(response))
                          .catch(error => reject(error.response))
                    })
                },

                getCourseSections(store, obj) {
                    return new Promise((resolve, reject) => {
                        http({
                            url: 'filters/get_course_sections',
                            method: 'POST',
                            data: obj
                        })
                          .then(response => resolve(response))
                          .catch(error => reject(error.response))
                    })
                }
            },
            getters: {
                getDistrictTerms (state) {
                    return state.districtTerms
                },
                getSelectedRequest (state) {
                    return state.selectedRequest
                },
                getUserRequests (state) {
                    return state.userRequests
                },
                getSummaryHighlight (state) {
                    return state.selectedSummaryData
                },
                ...vuexFilters.getters,
                GET_TEXT_ERROR(state) {
                    return state.textError;
                },
                GET_STUDENT_DETAILS(state) {
                    return state.studentDetails;
                },
                GET_RESULT_DATA(state) {
                    return state.resultData
                },
                GET_LOADER(state) {
                    return state.loader
                },
                GET_YEAR(state) {
                    return state.year
                },
                GET_CORRELATION_TYPE(state) {
                    return state.chosenCorrelationType
                },
                GET_ALL_SCHOOLS(state) {
                    return state.schools
                },
                GET_ALL_COURSE_TYPES(state) {
                    return state.allCourseTypes
                },
                GET_ALL_COURSES(state) {
                    return state.allCourses
                },
                GET_MEASUREMENT_TYPES(state) {
                    return state.measurementTypes
                },
                GET_DATASCORES(state) {
                    return state.dataScores
                },
                GET_TEACHER_LIST(state) {
                    return state.teacherList
                },
                GET_REPORTS(state) {
                    return state.reports
                },
                GET_SORT_RESULT(state) {
                    return state.sortResult
                },
                GET_SUBGROUP (state) {
                    return state.subGroup
                },
                GET_GRADE_OPTIONS(state) {
                    return state.gradeOptions;
                },
                GET_GRADE_TYPES(state) {
                    return state.gradeTypes
                }
            }
        }
    }
};

export default vuex;
